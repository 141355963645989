@font-face {
  font-family: 'Pragmatica';
  src: url("./fonts/Pragmatica-Light.eot");
  src: url("./fonts/Pragmatica-Light.eot") format('embedded-opentype'),
  url("./fonts/Pragmatica-Light.woff") format("woff"),
  url("./fonts/Pragmatica-Light.ttf") format('truetype'),
  url("./fonts/Pragmatica-Light.woff2") format("woff2");
}

@font-face {
  font-family: 'PragmaticaBold';
  src: url("./fonts/PragmaticaCond-Bold.eot");
  src: url("./fonts/PragmaticaCond-Bold.eot") format('embedded-opentype'),
  url("./fonts/PragmaticaCond-Bold.woff") format("woff"),
  url("./fonts/PragmaticaCond-Bold.ttf") format('truetype'),
  url("./fonts/PragmaticaCond-Bold.woff2") format("woff2");
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: 'Pragmatica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
  color: #652E87;
}
